@use 'sass:meta';
@use 'variables';
@use 'normalize' as *;
@use 'typographic' as *;
@use 'maximizer' as *;
$root-selector: '.max-ng' !default;

html,
body {
  height: 100%;
  font-family: Roboto, sans-serif;
}

#{$root-selector} {
  @include normalize();
  @include meta.load-css('kendo');
  @include meta.load-css('fonts');
  @include meta.load-css('icons');
  @include maximizer-components();
  @include typographic-styles();
}

@tailwind components;
@tailwind utilities;
