@mixin h6 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0.25px;
  @apply font-medium;
}

@mixin subtitle-1 {
  font-size: 0.875rem;
  line-height: 1rem;
  letter-spacing: 0.35px;
  @apply font-medium;
}

@mixin subtitle-2() {
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: 0.35px;
  @apply font-medium;
}

@mixin text-body() {
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.25px;
  @apply font-normal;
  &.bold {
    @apply font-medium;
  }
}

@mixin text-button() {
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: 0.5px;
  @apply font-medium;

  &.underline {
    text-decoration: underline;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.capital {
    text-transform: capitalize;
  }
}

@mixin text-inline-button() {
  font-size: 0.75rem;
  line-height: 1.025rem;
  letter-spacing: 0.5px;
  @apply font-medium;

  &.underline {
    text-decoration: underline;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.capital {
    text-transform: capitalize;
  }
}

@mixin caption() {
  font-size: 0.625rem;
  line-height: 0.75rem;
  letter-spacing: 0.35px;
  @apply font-normal;
}

@mixin text-link {
  color: var(--max-info-100);
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

@mixin typographic-styles {
  .h6 {
    @include h6();
  }
  .subtitle-1 {
    @include subtitle-1();
  }

  .subtitle-2 {
    @include subtitle-2();
  }

  .text-button {
    @include text-button();
  }

  .text-inline-button {
    @include text-inline-button();
  }

  .caption {
    @include caption();
  }

  .text-body {
    @include text-body();
  }

  .text-link {
    @include text-link();
  }
}

.max-ng {
  @include typographic-styles;
}
