$cdnUrl: 'https://cdn-maximizer-dev.azureedge.net';

@font-face {
  font-family: 'Google Material Icons';
  font-style: normal;
  font-weight: 400;
  src:
    url(#{$cdnUrl}/assets/fonts/material-icons/MaterialIconsOutlined-Regular.otf)
      format('opentype'),
    url(^assets/fonts/material-icons/MaterialIconsOutlined-Regular.otf)
      format('opentype');
}

@font-face {
  font-family: 'Google Material Icons';
  font-style: normal;
  font-weight: 700;
  src:
    url(#{$cdnUrl}/assets/fonts/material-icons/MaterialIcons-Regular.ttf)
      format('truetype'),
    url(^assets/fonts/material-icons/MaterialIcons-Regular.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Maximizer Icons';
  font-style: normal;
  font-weight: 400;
  src:
    url(#{$cdnUrl}/assets/fonts/maximizer-icons/maximizer-icons-2.0.7.ttf)
      format('truetype'),
    url(^assets/fonts/maximizer-icons/maximizer-icons-2.0.7.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src:
    url(#{$cdnUrl}/assets/fonts/fontawesome/webfonts/fa-light-300.woff2)
      format('woff2'),
    url(#{$cdnUrl}/assets/fonts/fontawesome/webfonts/fa-light-300.ttf)
      format('truetype'),
    url(^assets/fonts/fontawesome/webfonts/fa-light-300.woff2) format('woff2'),
    url(^assets/fonts/fontawesome/webfonts/fa-light-300.ttf) format('truetype');
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    url(#{$cdnUrl}/assets/fonts/fontawesome/webfonts/fa-regular-400.woff2)
      format('woff2'),
    url(#{$cdnUrl}/assets/fonts/fontawesome/webfonts/fa-regular-400.ttf)
      format('truetype'),
    url(^assets/fonts/fontawesome/webfonts/fa-regular-400.woff2) format('woff2'),
    url(^assets/fonts/fontawesome/webfonts/fa-regular-400.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    url(#{$cdnUrl}/assets/fonts/fontawesome/webfonts/fa-regular-400.woff2)
      format('woff2'),
    url(#{$cdnUrl}/assets/fonts/fontawesome/webfonts/fa-regular-400.ttf)
      format('truetype'),
    url(^assets/fonts/fontawesome/webfonts/fa-regular-400.woff2) format('woff2'),
    url(^assets/fonts/fontawesome/webfonts/fa-regular-400.ttf)
      format('truetype');
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src:
    url(#{$cdnUrl}/assets/fonts/fontawesome/webfonts/fa-solid-900.woff2)
      format('woff2'),
    url(#{$cdnUrl}/assets/fonts/fontawesome/webfonts/fa-solid-900.ttf)
      format('truetype'),
    url(^assets/fonts/fontawesome/webfonts/fa-solid-900.woff2) format('woff2'),
    url(^assets/fonts/fontawesome/webfonts/fa-solid-900.ttf) format('truetype');
}

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    url(#{$cdnUrl}/assets/fonts/fontawesome/webfonts/fa-brands-400.woff2)
      format('woff2'),
    url(#{$cdnUrl}/assets/fonts/fontawesome/webfonts/fa-brands-400.ttf)
      format('truetype'),
    url(^assets/fonts/fontawesome/webfonts/fa-brands-400.woff2) format('woff2'),
    url(^assets/fonts/fontawesome/webfonts/fa-brands-400.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src:
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-300.eot),
    url(^assets/fonts/roboto/roboto-v18-300.eot);
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-300.eot?#iefix)
      format('embedded-opentype'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-300.woff2) format('woff2'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-300.woff) format('woff'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-300.ttf) format('truetype'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-300.svg#Roboto) format('svg'),
    url(^assets/fonts/roboto/roboto-v18-300.eot?#iefix)
      format('embedded-opentype'),
    url(^assets/fonts/roboto/roboto-v18-300.woff2) format('woff2'),
    url(^assets/fonts/roboto/roboto-v18-300.woff) format('woff'),
    url(^assets/fonts/roboto/roboto-v18-300.ttf) format('truetype'),
    url(^assets/fonts/roboto/roboto-v18-300.svg#Roboto) format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src:
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-regular.eot),
    url(^assets/fonts/roboto/roboto-v18-regular.eot);
  src:
    local('Roboto'),
    local('Roboto-Regular'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-regular.eot?#iefix)
      format('embedded-opentype'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-regular.woff2) format('woff2'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-regular.woff) format('woff'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-regular.ttf)
      format('truetype'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-regular.svg#Roboto)
      format('svg'),
    url(^assets/fonts/roboto/roboto-v18-regular.eot?#iefix)
      format('embedded-opentype'),
    url(^assets/fonts/roboto/roboto-v18-regular.woff2) format('woff2'),
    url(^assets/fonts/roboto/roboto-v18-regular.woff) format('woff'),
    url(^assets/fonts/roboto/roboto-v18-regular.ttf) format('truetype'),
    url(^assets/fonts/roboto/roboto-v18-regular.svg#Roboto) format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src:
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-500.eot),
    url(^assets/fonts/roboto/roboto-v18-500.eot);
  src:
    local('Roboto Medium'),
    local('Roboto-Medium'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-500.eot?#iefix)
      format('embedded-opentype'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-500.woff2) format('woff2'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-500.woff) format('woff'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-500.ttf) format('truetype'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-500.svg#Roboto) format('svg'),
    url(/assets/fonts/roboto/roboto-v18-500.eot?#iefix)
      format('embedded-opentype'),
    url(^assets/fonts/roboto/roboto-v18-500.woff2) format('woff2'),
    url(^assets/fonts/roboto/roboto-v18-500.woff) format('woff'),
    url(^assets/fonts/roboto/roboto-v18-500.ttf) format('truetype'),
    url(^assets/fonts/roboto/roboto-v18-500.svg#Roboto) format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src:
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-700.eot),
    url(^assets/fonts/roboto/roboto-v18-700.eot);
  src:
    local('Roboto Bold'),
    local('Roboto-Bold'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-700.eot?#iefix)
      format('embedded-opentype'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-700.woff2) format('woff2'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-700.woff) format('woff'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-700.ttf) format('truetype'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-700.svg#Roboto) format('svg'),
    url(/assets/fonts/roboto/roboto-v18-700.eot?#iefix)
      format('embedded-opentype'),
    url(^assets/fonts/roboto/roboto-v18-700.woff2) format('woff2'),
    url(^assets/fonts/roboto/roboto-v18-700.woff) format('woff'),
    url(^assets/fonts/roboto/roboto-v18-700.ttf) format('truetype'),
    url(^assets/fonts/roboto/roboto-v18-700.svg#Roboto) format('svg');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src:
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-900.eot),
    url(^assets/fonts/roboto/roboto-v18-900.eot);
  src:
    local('Roboto Black'),
    local('Roboto-Black'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-900.eot?#iefix)
      format('embedded-opentype'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-900.woff2) format('woff2'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-900.woff) format('woff'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-900.ttf) format('truetype'),
    url(#{$cdnUrl}/assets/fonts/roboto/roboto-v18-900.svg#Roboto) format('svg'),
    url(^assets/fonts/roboto/roboto-v18-900.eot?#iefix)
      format('embedded-opentype'),
    url(^assets/fonts/roboto/roboto-v18-900.woff2) format('woff2'),
    url(^assets/fonts/roboto/roboto-v18-900.woff) format('woff'),
    url(^assets/fonts/roboto/roboto-v18-900.ttf) format('truetype'),
    url(^assets/fonts/roboto/roboto-v18-900.svg#Roboto) format('svg');
}
