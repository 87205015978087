@use 'kendo-theme-fluent/scss/index' as kendo-theme;
@use './typographic.scss' as typographic;
@use './maximizer.scss' as maximizer;

$kendo-config: (
  components: (
    'action-buttons',
    'autocomplete',
    'breadcrumb',
    'button',
    'calendar',
    'checkbox',
    'chip',
    'combobox',
    'datepicker',
    'daterangepicker',
    'datetimepicker',
    'dialog',
    'draggable',
    'drawer',
    'dropdownlist',
    'dropdowntree',
    'editor',
    'expansion-panel',
    'floating-label',
    'forms',
    'grid',
    'icon',
    'input',
    'list',
    'loader',
    'listview',
    'menu',
    'multiselect',
    'notification',
    'numerictextbox',
    'overlay',
    'pager',
    'panelbar',
    'popover',
    'popup',
    'progressbar',
    'radio',
    'skeleton',
    'switch',
    'table',
    'tabstrip',
    'textarea',
    'textbox',
    'timepicker',
    'timeselector',
    'toolbar',
    'tooltip',
    'treeview',
    'utils',
    'validator',
    'window',
  ),
);

@include kendo-theme.config($kendo-config);
@include kendo-theme.styles();

@mixin custom-kendo-taglist {
  kendo-taglist {
    .k-chip {
      --kendo-chip-bg: var(--max-inserv-5);
      --kendo-chip-hover-bg: var(--max-inserv-10);
      --kendo-chip-selected-bg: var(--max-inserv-90);
      --kendo-chip-disabled-bg: var(--max-neutral-30);
      --kendo-chip-disabled-text: white;
      --kendo-chip-text: black;
      --kendo-chip-hover-text: black;

      .k-chip-action.k-chip-remove-action > .k-icon-wrapper-host > .k-icon {
        &::before {
          display: none;
        }

        &::after {
          content: '\f00d';
          font-family: 'Font Awesome 6 Pro';
          cursor: pointer;
          color: black;
        }
      }
    }
  }
}

.k-input,
.k-picker {
  &:hover {
    box-shadow: var(--kendo-input-hover-shadow, none);
  }
  &:focus-within {
    box-shadow: var(--kendo-input-focus-shadow, none);
  }
}

.k-input-inner {
  text-indent: 0;
}

.k-arrow-menu,
.k-dots-menu {
  .k-menu {
    @apply p-0;
  }
  .k-item {
    &.k-menu-item {
      @apply text-component-text;

      &:focus {
        @apply outline-none;
      }
    }
  }
  .k-link {
    &.k-menu-link {
      @apply p-1 justify-center w-7.5 h-7.5 border-0;
    }

    .k-menu-expand-arrow {
      @apply m-0;
    }

    .k-menu-link-text {
      @apply hidden;
    }
  }
}

.k-arrow-menu {
  .k-item {
    &.k-menu-item {
      @apply rounded-full overflow-hidden;
      box-shadow:
        0 1px 3px rgb(0 0 0 / 10%),
        0 1px 2px rgb(0 0 0 / 18%);

      &:hover {
        box-shadow:
          0 3px 6px rgb(0 0 0 / 20%),
          0 3px 6px rgb(0 0 0 / 26%);
      }
    }
  }
}

.k-dots-menu {
  .k-link.k-menu-link {
    @apply bg-transparent;
  }

  .k-item.k-menu-item {
    @apply shadow-none;

    .k-menu-expand-arrow {
      .k-icon.k-i-caret-alt-down {
        &::before {
          content: '\e031';
        }
      }
    }
  }
}

.k-button {
  overflow: initial;
  border-radius: var(--kendo-border-radius-sm);

  &:not(.k-button-flat) {
    &.k-focus::after {
      @apply block opacity-100 rounded-none;
    }
  }

  &.k-button-flat {
    &::after {
      box-shadow: unset;
    }
  }

  .k-button-text {
    letter-spacing: 0.5px;

    &.underline {
      text-decoration: underline;
    }

    &.uppercase {
      text-transform: uppercase;
    }
  }

  &.k-button-solid-primary.k-hover,
  &.k-button-solid-primary.k-focus,
  &.k-button-solid-primary:hover,
  &.k-button-solid-primary:focus,
  &.k-button-solid-primary:active {
    box-shadow:
      0px 4px 5px 0px rgb(0 0 0 / 15%),
      0px 1px 10px 0px rgb(0 0 0 / 10%),
      0px 2px 5px 0px rgb(0 0 0 / 20%);
  }
  &.k-toggle-button.k-selected {
    @apply text-white;
    background-color: var(--max-inserv-90);
  }
}

.k-editor-toolbar .k-button,
kendo-buttongroup {
  --kendo-button-text: var(--max-neutral-60);
  --kendo-button-border: var(--max-neutral-40);
  --kendo-button-selected-bg: var(--max-inserv-5);
  --kendo-button-selected-text: var(--max-inserv-100);
  --kendo-button-selected-border: var(--max-inserv-100);
  --kendo-button-selected-hover-bg: var(--max-inserv-10);
  --kendo-button-selected-hover-text: var(--max-inserv-100);
  --kendo-button-selected-hover-border: var(--max-inserv-100);
  --kendo-button-hover-bg: var(--max-inserv-10);
  --kendo-button-hover-text: var(--max-neutral-60);
  --kendo-button-hover-border: var(--max-neutral-40);
  --kendo-button-border-width: 1px;

  kendo-button.k-button.k-toggle-button {
    &.k-selected,
    &.k-selected:hover,
    &.k-hover,
    &:hover {
      border: var(--kendo-button-border-width, 1px) solid
        var(--INTERNAL--kendo-button-border, initial);
      color: var(--INTERNAL--kendo-button-text, inherit) !important;
      background-color: var(--INTERNAL--kendo-button-bg, initial) !important;
    }
  }
}

.k-editor-toolbar {
  --kendo-toolbar-bg: var(--max-neutral-5);
  --kendo-toolbar-border: var(--core-border);

  kendo-buttongroup,
  .k-button {
    --kendo-button-bg: var(--tb-white);
    --kendo-button-text: var(--max-neutral-90);
    --kendo-button-hover-text: var(--max-inserv-100);
    --kendo-button-disabled-text: var(--max-neutral-60);
    --kendo-button-disabled-border: var(--core-border);

    .k-button-icon {
      font-size: 0.75rem;
      min-width: unset;
      min-height: unset;
    }
  }

  .k-editor-custom-toolbar {
    --kendo-toolbar-border-width: 0;
    --kendo-toolbar-bg: var(--tb-white);
    --INTERNAL--kendo-toolbar-padding-x: 0;
    --INTERNAL--kendo-toolbar-padding-y: 0;

    .k-button {
      --kendo-button-border-width: 0;
    }

    @apply shadow-circle;
  }
}

.k-icon {
  margin: unset;
}

.k-dialog-titlebar {
  border-width: var(--kendo-dialog-titlebar-border-width, 0);
  border-style: solid;
}

.k-grid {
  border-radius: var(--tb-kendo-border-radius-md);
  .k-grid-aria-root {
    border-radius: var(--tb-kendo-border-radius-md);
  }
  .k-cell-link {
    color: var(--max-info-100);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .k-hierarchy-cell {
    .k-i-plus::before {
      content: '\e005';
    }

    .k-i-minus::before {
      content: '\e001';
    }
  }

  th.k-header.k-table-th.k-checkbox-sm,
  td.k-table-td.k-touch-action-auto.k-checkbox-sm {
    text-overflow: clip;
  }

  .k-table-th > .k-checkbox {
    vertical-align: middle;
  }

  input:not(.k-checkbox) {
    @apply h-8 bg-white pl-3;

    &:not(.ng-invalid) {
      @apply border-component-border hover:border-neutral-90;
    }
  }
  .max-check-status.k-checkbox:checked,
  .max-check-status.k-checkbox.k-checked {
    border-color: var(--max-finserv-100);
    background-color: var(--max-finserv-100);
  }
  .k-grid-norecords {
    @apply table-row;

    .k-table-td {
      @apply border-0;
    }
  }

  .k-grid-header {
    padding: 0px 9px 0px 0px !important;

    .k-table-th {
      border-right: 1px solid var(--core-border);
    }

    .k-grid-header-wrap {
      border: 0;
    }
  }

  &.no-border {
    border: 0;
    --INTERNAL--kendo-grid-cell-padding-x: 0.5rem;
    --INTERNAL--kendo-grid-header-padding-x: 0.5rem;

    .k-table-th {
      border-right: 0;
    }
  }

  &.clickeable-row {
    td:not(.k-command-cell) {
      cursor: pointer;
    }
  }

  .k-grouping-header:has(.k-grouping-drop-container) {
    flex-wrap: nowrap;
  }
}

.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked {
  border-inline-end-width: 0px;

  .k-grouping-row .k-table-td,
  .k-table-row .k-table-td:not([aria-colindex='1']),
  .k-table-th,
  .k-group-cell {
    border-inline-start-width: 0px;
    border-inline-end-width: 0;
  }
}

.k-grid-content,
.k-grid-content-locked {
  .k-table-row:last-child {
    > td,
    > .k-table-td {
      border-bottom-width: var(--kendo-grid-grouping-row-border-width-y, 1px);
    }
  }
}

.k-alt {
  @apply bg-neutral-5;
}

.k-panelbar {
  @apply m-2 shadow-card rounded;

  .k-expanded .k-panelbar-item-text {
    @include typographic.h6();
    @apply text-black hover:cursor-pointer;
  }

  .k-panelbar-item-text {
    @include typographic.text-body-1();
    @apply text-neutral-70 hover:cursor-pointer;
  }

  .k-panelbar-header .k-link.k-selected:hover {
    @apply bg-inserv-10;
  }

  .k-panelbar-content {
    @apply bg-white;
  }
}

.k-breadcrumb-delimiter-icon {
  @apply inline-flex;
  font-size: var(--kendo-breadcrumb-delimiter-icon-font-size);

  .k-font-icon {
    font-size: var(--kendo-breadcrumb-delimiter-icon-font-size);
  }
}

.k-listview-content,
.k-grid-content {
  @include maximizer.slimScroll();
}

.k-tooltip-wrapper.k-error {
  @apply border border-solid border-error;
  border-radius: var(--tb-kendo-border-radius-md);

  .k-tooltip {
    border-radius: var(--tb-kendo-border-radius-md);

    .k-callout {
      &.k-callout-n {
        @apply border-b-error;
      }
      &.k-callout-s {
        @apply border-t-error;
      }
      &.k-callout-w {
        @apply border-r-error;
      }
      &.k-callout-e {
        @apply border-l-error;
      }
    }
  }
}

.k-expander {
  &.k-focus {
    @apply shadow-none;
  }
}

.k-expander-header {
  &:focus {
    @apply shadow-none;
  }
}

.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
  &::after {
    border-bottom-width: var(--kendo-tabstrip-indicator-size, 0.125rem);
    border-color: var(--max-inserv-100);
  }

  &:focus {
    background-color: transparent;
  }

  &:active {
    background-color: var(--max-inserv-10);

    &::after {
      border-bottom-width: 0px;
    }
  }
}

.k-popup > .k-list {
  border-radius: inherit;

  .k-list-item {
    padding: var(--INTERNAL--kendo-list-item-padding-y, 0)
      var(--INTERNAL--kendo-list-item-padding-x, 0);
    line-height: var(
      --INTERNAL--kendo-list-item-line-height,
      normal
    ) !important;
  }
}

.k-checkbox-md {
  --INTERNAL--kendo-checkbox-width: 1rem;
  --INTERNAL--kendo-checkbox-height: 1rem;
}

.k-switch-lg {
  --INTERNAL--kendo-switch-width: 4rem;
  --INTERNAL--kendo-switch-height: 2rem;

  .k-switch-thumb {
    --INTERNAL--kendo-switch-thumb-width: 1.5rem;
    --INTERNAL--kendo-switch-thumb-height: 1.5rem;
  }
  .k-switch-thumb-wrap {
    --INTERNAL--kendo-switch-thumb-offset: 0.625rem;
  }
}

.k-switch:focus,
.k-switch.k-focus {
  &::after {
    outline: 0;
  }
}

.k-disabled.k-input {
  --kendo-button-solid-base-bg: var(--kendo-neutral-30);
  --kendo-button-solid-base-text: var(--kendo-neutral-90);

  &.k-textbox,
  &.k-combobox,
  &.k-dropdownlist,
  &.k-multiselect,
  &.k-datepicker,
  &.k-numerictextbox {
    --kendo-input-disabled-bg: var(--kendo-neutral-20);
    --kendo-input-disabled-text: var(--kendo-neutral-90);
  }
}
.k-clear-value {
  display: none;
}
.k-input {
  &.k-focus,
  &:hover {
    .k-clear-value {
      display: inline-flex;
    }
  }
}
.k-multiselecttree .k-input .k-input-prefix {
  @apply pl-1;
}

.k-multiselect.no-input {
  .k-input-values {
    @apply cursor-default;
    .k-searchbar {
      @apply hidden;
    }

    @include custom-kendo-taglist;
  }
}

.k-calendar {
  .k-calendar-td {
    &.k-range-start,
    &.k-range-end {
      --kendo-calendar-cell-selected-bg: var(--max-inserv-100);
      --kendo-calendar-cell-text: var(--max-white);
      --kendo-calendar-cell-selected-focus-shadow: none;
      --kendo-calendar-cell-border-radius: 0;
    }
    &.k-range-mid {
      --kendo-calendar-cell-border-radius: 0;
      --kendo-calendar-cell-hover-text: var(--max-white);
    }
    &.k-range-start.k-range-end::before {
      border-radius: 0.25rem;
    }
  }
}

kendo-listview.k-listview-slim {
  > .k-listview-header {
    @apply h-8;
    padding-block: 0;
    background-color: white;
    border-block-end-width: var(--kendo-listview-border-width, 1px);
    border-block-color: var(--max-neutral-30);

    .subtitle-2 {
      @apply leading-[1.15rem];
    }
  }

  > .k-listview-footer {
    padding-block: 0;
    border-style: hidden;
  }
}

kendo-menu {
  .k-menu .k-icon {
    color: var(--max-neutral-90);
  }
}

kendo-multiselecttree.k-treeview-slim {
  .k-input-values {
    padding: 0;
  }
  .k-treeview-top,
  .k-treeview-mid,
  .k-treeview-bot {
    @apply h-8;
  }

  @include custom-kendo-taglist;

  kendo-taglist {
    .k-chip-actions {
      display: none;
    }
  }
}
