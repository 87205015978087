@mixin h3 {
  @apply leading-18 font-normal;
  font-size: 3rem;
  letter-spacing: 0;
}

@mixin h4 {
  @apply font-normal;
  font-size: 2.125rem;
  line-height: 3.1875rem;
  letter-spacing: 0.25px;
}

@mixin h5() {
  @apply leading-9 font-medium;
  font-size: 1.5rem;
  letter-spacing: 0;
}

@mixin h6 {
  @apply leading-7.5 font-medium;
  font-size: 1.25rem;
  letter-spacing: 0.25px;
}

@mixin subtitle-1 {
  @apply leading-6 font-medium;
  font-size: 1rem;
  letter-spacing: 0.35px;
}

@mixin subtitle-2() {
  @apply font-medium;
  line-height: 1.3125rem;
  font-size: 0.875rem;
  letter-spacing: 0.35px;
}

@mixin menu-1 {
  @apply leading-6 font-bold;
  font-size: 0.875rem;
  letter-spacing: 0.4px;
}

@mixin menu-2 {
  @apply font-bold;
  line-height: 1.3125rem;
  font-size: 0.75rem;
  letter-spacing: 0.4px;
}

@mixin text-body-1($line-height: 1.5rem) {
  @apply font-normal;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: $line-height;
}

@mixin text-body-2($line-height: 1.3125rem) {
  @apply font-normal;
  font-size: 0.875rem;
  letter-spacing: 0.35px;
  line-height: $line-height;
}

@mixin text-button($line-height: 1.025rem) {
  @apply font-medium;
  line-height: $line-height;
  font-size: 0.875rem;
  letter-spacing: 0.5px;

  &.underline {
    text-decoration: underline;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

@mixin text-caption($line-height: 1rem) {
  @apply font-normal;
  line-height: $line-height;
  font-size: 0.75rem;
  letter-spacing: 0.4px;
}

@mixin text-overline() {
  @apply font-normal;
  line-height: 0.73125rem;
  font-size: 0.625rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

@mixin text-inherit {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}

@mixin text-link {
  color: var(--max-info-100);
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

@mixin typographic-styles {
  h3 {
    @include h3();
  }

  h4 {
    @include h4();
  }

  h5 {
    @include h5();
  }

  h6 {
    @include h6();
  }

  h3 a,
  h4 a,
  h5 a,
  h6 a {
    font-size: inherit;
  }

  .subtitle-1 {
    @include subtitle-1();

    &-lg {
      font-size: 1.125rem;
    }
  }

  .subtitle-2 {
    @include subtitle-2();
  }

  .menu-1 {
    @include menu-1();
  }

  .menu-2 {
    @include menu-2();
  }

  .text-body-1 {
    @include text-body-1();
  }

  .text-body-2 {
    @include text-body-2();
  }

  .text-button {
    @include text-button();
  }

  .text-caption {
    @include text-caption();
  }

  .text-overline {
    @include text-overline();
  }

  .text-inherit {
    @include text-inherit();
  }

  .text-link {
    @include text-link();
  }
}
