@mixin slimScroll {
  &::-webkit-scrollbar {
    @apply w-2 h-2;
  }

  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  &::-webkit-scrollbar-thumb {
    @apply rounded;
    background-color: rgba($color: #000000, $alpha: 0.4);
  }
}

@mixin maximizer-components {
  .avatar {
    @apply flex items-center justify-center rounded-full bg-contain bg-primary-100 shrink-0;

    &-xs {
      @apply h-6 w-6 text-xs;
      letter-spacing: 0.4px;
    }

    &-sm {
      @apply h-8 w-8 text-sm;
      letter-spacing: 0.35px;
    }

    &-md {
      @apply h-9 w-9 text-base;
      letter-spacing: 0.35px;
    }

    &-lg {
      @apply w-13 h-13 text-lg;
      letter-spacing: 0.25px;
    }

    &-xl {
      @apply w-15 h-15 text-xl;
      letter-spacing: 0.25px;
    }

    &-xxl {
      @apply w-20 h-20 text-2xl;
    }

    &[style*='background-image'] {
      @apply text-transparent bg-transparent;
    }

    &-neutral-20 {
      @apply bg-neutral-20;
    }

    &-neutral-30 {
      @apply bg-neutral-30;
    }

    &-square {
      @apply rounded;
    }

    &-border {
      @apply border-solid border border-neutral-60;
    }
  }

  maximizer-sorteable-list {
    .sortable-draggable-active[style*='position: fixed'] {
      display: none;
    }
  }

  maximizer-confirm-dialog {
    .k-dialog-title {
      @apply whitespace-nowrap text-ellipsis overflow-hidden block text-center;
    }
  }

  maximizer-timeline-header {
    kendo-popup:has(.k-multiselecttree-popup) {
      z-index: 1000;
    }
  }
}
