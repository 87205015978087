html,
body {
  height: 100%;
  font-family: Roboto, sans-serif;
}

@import './variables.scss';

.max-ng {
  @import './kendo.scss';
}

@import './tailwind.scss';
@import './fonts.scss';
@import './icons.scss';
@import './typographic.scss';
@import './maximizer.scss';
