@forward 'kendo-theme-fluent/scss/index';
@use 'kendo-theme-fluent/scss/index' as *;
@use './typographic.scss' as typographic;
@use './maximizer.scss' as maximizer;

// Core
@include core-styles();

// Color system css variables
@include color-system-styles();
@include kendo-elevation--styles-legacy();

// Typography
@include kendo-typography--styles();

// Transitions
@include kendo-transition--styles();

// Generic content
@include kendo-icon--styles();
@include kendo-messagebox--styles();

// Primitive components
@include kendo-action-buttons--styles();
@include kendo-list--styles();
@include kendo-listgroup--styles();
@include kendo-overlay--styles();
@include kendo-popup--styles();
@include kendo-ripple--styles();
@include kendo-table--styles();
@include kendo-virtual-scroller--styles();
@include kendo-no-data--styles();

// Indicators
@include kendo-chip--styles();
@include kendo-loader--styles();
@include kendo-skeleton--styles();
@include kendo-tooltip--styles();
// @include kendo-avatar--styles();
// @include kendo-badge--styles();
// @include kendo-color-preview--styles();
// @include kendo-time-marker--styles();

// Form helpers
@include kendo-form--styles();
@include kendo-validator--styles();
@include kendo-floating-label--styles();

// Native forms
@include kendo-button--styles();
@include kendo-input--styles();
@include kendo-textbox--styles();
@include kendo-textarea--styles();
@include kendo-checkbox--styles();
@include kendo-progressbar--styles();
@include kendo-radio--styles();
// @include kendo-split-button--styles();
// @include kendo-menu-button--styles();
// @include kendo-listbox--styles();
// @include kendo-slider--styles();

// Form requirements
@include kendo-calendar--styles();
@include kendo-time-selector--styles();

// Augmented forms
@include kendo-autocomplete--styles();
@include kendo-combobox--styles();
@include kendo-date-input--styles();
@include kendo-date-picker--styles();
@include kendo-time-picker--styles();
@include kendo-date-time-picker--styles();
@include kendo-date-range-picker--styles();
@include kendo-dropdown-list--styles();
@include kendo-dropdown-tree--styles();
@include kendo-masked-textbox--styles();
@include kendo-multiselect--styles();
@include kendo-numeric-textbox--styles();
@include kendo-switch--styles();
@include kendo-upload--styles();
@include kendo-dropzone--styles();
// @include kendo-captcha--styles();
// @include kendo-color-palette--styles();
// @include kendo-color-gradient--styles();
// @include kendo-color-editor--styles();
// @include kendo-color-picker--styles();
// @include kendo-time-duration-picker--styles();
// @include kendo-dropdown-grid--styles();
// @include kendo-rating--styles();
// @include kendo-searchbox--styles();
// @include kendo-otp--styles();

// Command interfaces
@include kendo-menu--styles();
@include kendo-toolbar--styles();
// @include kendo-appbar--styles();
// @include kendo-fab--styles();

// Popups and modals
@include kendo-window--styles();
@include kendo-dialog--styles();
@include kendo-drawer--styles();
@include kendo-notification--styles();
@include kendo-popover--styles();
// @include kendo-action-sheet--styles();
// @include kendo-responsive-panel--styles();

// Navigation
@include kendo-breadcrumb--styles();
@include kendo-pager--styles();
@include kendo-tabstrip--styles();
@include kendo-treeview--styles();
// @include kendo-bottom-navigation--styles();
// @include kendo-stepper--styles();
// @include kendo-wizard--styles();

// Layout & containers
@include kendo-expander--styles();
@include kendo-panelbar--styles();
// @include kendo-card--styles();
// @include kendo-panel--styles();
// @include kendo-splitter--styles();
// @include kendo-tile-layout--styles();
// @include kendo-grid-layout--styles();
// @include kendo-stack-layout--styles();
// @include kendo-dock-manager--styles();
// @include kendo-chart-wizard--styles();

// Data management
@include kendo-grid--styles();
@include kendo-listview--styles();
// @include kendo-spreadsheet--styles();
// @include kendo-pivotgrid--styles();
// @include kendo-treelist--styles();
// @include kendo-filter--styles();
// @include kendo-file-manager--styles();
// @include kendo-task-board--styles();

// Editors
@include kendo-editor--styles();
// @include kendo-image-editor--styles();

// Scheduling
// @include kendo-gantt--styles();
// @include kendo-scheduler--styles();

// Misc
// @include kendo-adaptive--styles();
// @include kendo-chat--styles();
// @include kendo-media-player--styles();
// @include kendo-timeline--styles();
// @include kendo-pdf-viewer--styles();
// @include kendo-scroller--styles();
// @include kendo-scrollview--styles();
// @include kendo-prompt--styles();

// Dataviz
// @include kendo-dataviz--styles();
// @include kendo-map--styles();
// @include kendo-orgchart--styles();
// @include kendo-signature--styles();

@mixin custom-kendo-taglist {
  kendo-taglist {
    .k-chip {
      --kendo-chip-bg: var(--max-inserv-5);
      --kendo-chip-hover-bg: var(--max-inserv-10);
      --kendo-chip-selected-bg: var(--max-inserv-90);
      --kendo-chip-disabled-bg: var(--max-neutral-30);
      --kendo-chip-disabled-text: white;
      --kendo-chip-text: black;
      --kendo-chip-hover-text: black;

      .k-chip-action.k-chip-remove-action > .k-icon-wrapper-host > .k-icon {
        &::before {
          display: none;
        }

        &::after {
          content: '\f00d';
          font-family: 'Font Awesome 6 Pro';
          cursor: pointer;
          color: black;
        }
      }
    }
  }
}

.k-input,
.k-picker {
  &:hover {
    box-shadow: var(--kendo-input-hover-shadow, none);
  }
  &:focus-within {
    box-shadow: var(--kendo-input-focus-shadow, none);
  }
}

.k-input-inner {
  text-indent: 0;
}

.k-arrow-menu,
.k-dots-menu {
  .k-menu {
    @apply p-0;
  }
  .k-item {
    &.k-menu-item {
      @apply text-component-text;

      &:focus {
        @apply outline-none;
      }
    }
  }
  .k-link {
    &.k-menu-link {
      @apply p-1 justify-center w-7.5 h-7.5 border-0;
    }

    .k-menu-expand-arrow {
      @apply m-0;
    }

    .k-menu-link-text {
      @apply hidden;
    }
  }
}

.k-arrow-menu {
  .k-item {
    &.k-menu-item {
      @apply rounded-full overflow-hidden;
      box-shadow:
        0 1px 3px rgb(0 0 0 / 10%),
        0 1px 2px rgb(0 0 0 / 18%);

      &:hover {
        box-shadow:
          0 3px 6px rgb(0 0 0 / 20%),
          0 3px 6px rgb(0 0 0 / 26%);
      }
    }
  }
}

.k-dots-menu {
  .k-link.k-menu-link {
    @apply bg-transparent;
  }

  .k-item.k-menu-item {
    @apply shadow-none;

    .k-menu-expand-arrow {
      .k-icon.k-i-caret-alt-down {
        &::before {
          content: '\e031';
        }
      }
    }
  }
}

.k-button {
  overflow: initial;
  border-radius: var(--kendo-border-radius-sm);

  &:not(.k-button-flat) {
    &.k-focus::after {
      @apply block opacity-100 rounded-none;
    }
  }

  &.k-button-flat {
    &::after {
      box-shadow: unset;
    }
  }

  .k-button-text {
    letter-spacing: 0.5px;

    &.underline {
      text-decoration: underline;
    }

    &.uppercase {
      text-transform: uppercase;
    }
  }

  &.k-button-solid-primary.k-hover,
  &.k-button-solid-primary.k-focus,
  &.k-button-solid-primary:hover,
  &.k-button-solid-primary:focus,
  &.k-button-solid-primary:active {
    box-shadow:
      0px 4px 5px 0px rgb(0 0 0 / 15%),
      0px 1px 10px 0px rgb(0 0 0 / 10%),
      0px 2px 5px 0px rgb(0 0 0 / 20%);
  }
  &.k-toggle-button.k-selected {
    @apply text-white;
    background-color: var(--max-inserv-90);
  }
}

.k-editor-toolbar .k-button,
kendo-buttongroup {
  --kendo-button-text: var(--max-neutral-60);
  --kendo-button-border: var(--max-neutral-40);
  --kendo-button-selected-bg: var(--max-inserv-5);
  --kendo-button-selected-text: var(--max-inserv-100);
  --kendo-button-selected-border: var(--max-inserv-100);
  --kendo-button-selected-hover-bg: var(--max-inserv-10);
  --kendo-button-selected-hover-text: var(--max-inserv-100);
  --kendo-button-selected-hover-border: var(--max-inserv-100);
  --kendo-button-hover-bg: var(--max-inserv-10);
  --kendo-button-hover-text: var(--max-neutral-60);
  --kendo-button-hover-border: var(--max-neutral-40);
  --kendo-button-border-width: 1px;

  .k-button.k-toggle-button {
    &.k-selected,
    &.k-selected:hover,
    &.k-hover,
    &:hover {
      border: var(--kendo-button-border-width, 1px) solid
        var(--INTERNAL--kendo-button-border, initial);
      color: var(--INTERNAL--kendo-button-text, inherit) !important;
      background-color: var(--INTERNAL--kendo-button-bg, initial) !important;
    }
  }
}

.k-editor-toolbar {
  --kendo-toolbar-bg: var(--max-neutral-5);
  --kendo-toolbar-border: var(--core-border);

  kendo-buttongroup,
  .k-button {
    --kendo-button-bg: var(--tb-white);
    --kendo-button-text: var(--max-neutral-90);
    --kendo-button-hover-text: var(--max-inserv-100);
    --kendo-button-disabled-text: var(--max-neutral-60);
    --kendo-button-disabled-border: var(--core-border);

    .k-button-icon {
      font-size: 0.75rem;
      min-width: unset;
      min-height: unset;
    }
  }

  .k-editor-custom-toolbar {
    --kendo-toolbar-border-width: 0;
    --kendo-toolbar-bg: var(--tb-white);
    --INTERNAL--kendo-toolbar-padding-x: 0;
    --INTERNAL--kendo-toolbar-padding-y: 0;

    .k-button {
      --kendo-button-border-width: 0;
    }

    @apply shadow-circle;
  }
}

.k-icon {
  margin: unset;
}

.k-dialog-titlebar {
  border-width: var(--kendo-dialog-titlebar-border-width, 0);
  border-style: solid;
}

.k-grid {
  border-radius: var(--tb-kendo-border-radius-md);
  .k-grid-aria-root {
    border-radius: var(--tb-kendo-border-radius-md);
  }
  .k-cell-link {
    color: var(--max-info-100);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .k-hierarchy-cell {
    .k-i-plus::before {
      content: '\e005';
    }

    .k-i-minus::before {
      content: '\e001';
    }
  }

  th.k-header.k-table-th.k-checkbox-sm,
  td.k-table-td.k-touch-action-auto.k-checkbox-sm {
    text-overflow: clip;
  }

  .k-table-th > .k-checkbox {
    vertical-align: middle;
  }

  input:not(.k-checkbox) {
    @apply h-8 bg-white pl-3;

    &:not(.ng-invalid) {
      @apply border-component-border hover:border-neutral-90;
    }
  }
  .max-check-status {
    @apply rounded-full;

    &.k-checkbox:checked,
    &.k-checkbox.k-checked {
      border-color: var(--max-finserv-100);
      background-color: var(--max-finserv-100);
    }
  }
  .k-grid-norecords {
    @apply table-row;

    .k-table-td {
      @apply border-0;
    }
  }

  .k-grid-header {
    padding: 0px 9px 0px 0px !important;

    .k-table-th {
      border-right: 1px solid var(--core-border);
    }

    .k-grid-header-wrap {
      border: 0;
    }
  }

  &.no-border {
    border: 0;
    --INTERNAL--kendo-grid-cell-padding-x: 0.5rem;
    --INTERNAL--kendo-grid-header-padding-x: 0.5rem;

    .k-table-th {
      border-right: 0;
    }
  }

  &.medium-size-header {
    --INTERNAL--kendo-grid-cell-padding-x: 0.5rem;

    th {
      height: 2rem;

      span {
        height: inherit;
      }
      &:last-child {
        border-right: 0;
      }
    }

    tr:last-child {
      td {
        border: 0;
      }
    }

    kendo-grid-list {
      .k-grid-content {
        overflow-y: auto;
      }
    }
  }

  &.clickeable-row {
    td:not(.k-command-cell) {
      cursor: pointer;
    }
  }

  .k-grouping-header:has(.k-grouping-drop-container) {
    flex-wrap: nowrap;
  }
}

.k-grid-content-locked,
.k-grid-footer-locked,
.k-grid-header-locked {
  border-inline-end-width: 0px;

  .k-grouping-row .k-table-td,
  .k-table-row .k-table-td:not([aria-colindex='1']),
  .k-table-th,
  .k-group-cell {
    border-inline-start-width: 0px;
    border-inline-end-width: 0;
  }
}

.k-grid-content,
.k-grid-content-locked {
  .k-table-row:last-child {
    > td,
    > .k-table-td {
      border-bottom-width: var(--kendo-grid-grouping-row-border-width-y, 1px);
    }
  }
}

.k-alt {
  @apply bg-neutral-5;
}

.k-panelbar {
  @apply m-2 shadow-card rounded;

  .k-expanded .k-panelbar-item-text {
    @include typographic.h6();
    @apply text-black hover:cursor-pointer;
  }

  .k-panelbar-item-text {
    @include typographic.text-body-1();
    @apply text-neutral-70 hover:cursor-pointer;
  }

  .k-panelbar-header .k-link.k-selected:hover {
    @apply bg-inserv-10;
  }

  .k-panelbar-content {
    @apply bg-white;
  }
}

kendo-breadcrumb {
  .k-breadcrumb-delimiter-icon {
    font-size: var(--kendo-breadcrumb-delimiter-icon-font-size);
  }
}

.k-listview-content,
.k-grid-content {
  @include maximizer.slimScroll();
}

.k-tooltip-wrapper.k-error {
  @apply border border-solid border-error;
  border-radius: var(--tb-kendo-border-radius-md);

  .k-tooltip {
    border-radius: var(--tb-kendo-border-radius-md);

    .k-callout {
      &.k-callout-n {
        @apply border-b-error;
      }
      &.k-callout-s {
        @apply border-t-error;
      }
      &.k-callout-w {
        @apply border-r-error;
      }
      &.k-callout-e {
        @apply border-l-error;
      }
    }
  }
}

.k-expander {
  &.k-focus {
    @apply shadow-none;
  }
}

.k-expander-header {
  &:focus {
    @apply shadow-none;
  }
}

.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
  &::after {
    border-bottom-width: var(--kendo-tabstrip-indicator-size, 0.125rem);
    border-color: var(--max-inserv-100);
  }

  &:focus {
    background-color: transparent;
  }

  &:active {
    background-color: var(--max-inserv-10);

    &::after {
      border-bottom-width: 0px;
    }
  }
}

.k-popup > .k-list {
  border-radius: inherit;

  .k-list-item {
    padding: var(--INTERNAL--kendo-list-item-padding-y, 0)
      var(--INTERNAL--kendo-list-item-padding-x, 0);
    line-height: var(
      --INTERNAL--kendo-list-item-line-height,
      normal
    ) !important;
  }
}

.k-checkbox-md {
  --INTERNAL--kendo-checkbox-width: 1rem;
  --INTERNAL--kendo-checkbox-height: 1rem;
}

.k-switch-lg {
  --INTERNAL--kendo-switch-width: 4rem;
  --INTERNAL--kendo-switch-height: 2rem;

  .k-switch-thumb {
    --INTERNAL--kendo-switch-thumb-width: 1.5rem;
    --INTERNAL--kendo-switch-thumb-height: 1.5rem;
  }
  .k-switch-thumb-wrap {
    --INTERNAL--kendo-switch-thumb-offset: 0.625rem;
  }
}

.k-switch:focus,
.k-switch.k-focus {
  &::after {
    outline: 0;
  }
}

.k-disabled.k-input {
  --kendo-button-solid-base-bg: var(--kendo-neutral-30);
  --kendo-button-solid-base-text: var(--kendo-neutral-90);

  &.k-textbox,
  &.k-combobox,
  &.k-dropdownlist,
  &.k-multiselect,
  &.k-datepicker,
  &.k-numerictextbox {
    --kendo-input-disabled-bg: var(--kendo-neutral-20);
    --kendo-input-disabled-text: var(--kendo-neutral-90);
  }
}
.k-clear-value {
  display: none;
}
.k-input {
  &.k-focus,
  &:hover {
    .k-clear-value {
      display: inline-flex;
    }
  }
}
.k-multiselecttree .k-input .k-input-prefix {
  @apply pl-1;
}

.k-multiselect.no-input {
  .k-input-values {
    @apply cursor-default;
    .k-searchbar {
      @apply hidden;
    }

    @include custom-kendo-taglist;
  }
}

.k-calendar {
  .k-calendar-td {
    &.k-range-start,
    &.k-range-end {
      --kendo-calendar-cell-selected-bg: var(--max-inserv-100);
      --kendo-calendar-cell-text: var(--max-white);
      --kendo-calendar-cell-selected-focus-shadow: none;
      --kendo-calendar-cell-border-radius: 0;
    }
    &.k-range-mid {
      --kendo-calendar-cell-border-radius: 0;
      --kendo-calendar-cell-hover-text: var(--max-white);
    }
    &.k-range-start.k-range-end::before {
      border-radius: 0.25rem;
    }
  }
}

kendo-listview.k-listview-slim {
  > .k-listview-header {
    @apply h-8;
    padding-block: 0;
    background-color: white;
    border-block-end-width: var(--kendo-listview-border-width, 1px);
    border-block-color: var(--max-neutral-30);

    .subtitle-2 {
      @apply leading-[1.15rem];
    }
  }

  > .k-listview-footer {
    padding-block: 0;
    border-style: hidden;
  }
}

kendo-menu {
  .k-menu .k-icon {
    color: var(--max-neutral-90);
  }
}

kendo-multiselecttree.k-treeview-slim {
  .k-input-values {
    padding: 0;
  }
  .k-treeview-top,
  .k-treeview-mid,
  .k-treeview-bot {
    @apply h-8;
  }

  @include custom-kendo-taglist;

  kendo-taglist {
    .k-chip-actions {
      display: none;
    }
  }
}

kendo-upload.k-upload {
  @apply border-0;

  .k-upload-files {
    @apply border-0;
    @include maximizer.slimScroll();
  }
  .k-dropzone:hover {
    @apply bg-neutral-10;
  }
  .k-upload-files .k-file:first-child {
    @apply mt-2;
  }

  .k-upload-files .k-file,
  .k-upload-files .k-file:first-child,
  .k-upload-files .k-file:last-child {
    @apply border-y border-x border-neutral-10 rounded-sm;
  }

  .k-actions {
    @apply border-t-0 pr-0;
  }

  .k-dropzone {
    @apply flex flex-row rounded mb-2;
  }

  .k-file.k-file-invalid .k-file-validation-message,
  .k-file.k-file-error .k-file-summary {
    @apply text-error;
  }

  .k-file.k-file-success .k-file-summary {
    @apply text-success;
  }

  .k-i-refresh-sm {
    composes: k-button-solid-primary k-button-solid;
  }
  .k-height-container {
    height: auto !important;
  }

  --kendo-upload-dropzone-bg: var(--max-neutral-10);
  --kendo-upload-dropzone-padding-y: 1rem;
  --kendo-upload-dropzone-hover-bg: var(--max-neutral-10);
  --kendo-upload-border: var(--max-neutral-10);
  --kendo-upload-dropzone-border: var(--max-neutral-20);

  --kendo-upload-success-text: var(--max-finserv-100);
  --kendo-upload-error-text: var(--max-error-100);
}

.k-progressbar-horizontal .k-progress-status-wrap {
  margin-block-start: 0px;
}
