:root {
  --core-border: #ccc;
  --core-text: #333;
  --core-hover: #ebebeb;

  --max-primary-110: #a3c916;
  --max-primary-100: #bff102;
  --max-primary-90: #d3fd3a;
  --max-primary-10: #f0febd;
  --max-primary-5: #f1ffdb;
  --max-inserv-120: #065a7d;
  --max-inserv-110: #0f6c94;
  --max-inserv-100: #117ba8;
  --max-inserv-90: #54a0c1;
  --max-inserv-10: #b9d8e6;
  --max-inserv-5: #e6eff2;
  --max-finserv-110: #427400;
  --max-finserv-100: #4d8700;
  --max-finserv-90: #689b25;
  --max-finserv-10: #d6ebb6;
  --max-finserv-5: #f1ffdb;
  --max-black: #000000;
  --max-neutral-95: #262626;
  --max-neutral-90: #343738;
  --max-neutral-80: #464a4c;
  --max-neutral-70: #676d70;
  --max-neutral-60: #797e7f;
  --max-neutral-40: #adb1b2;
  --max-neutral-30: #d2d7d9;
  --max-neutral-20: #e0e5e5;
  --max-neutral-25: #cccccc;
  --max-neutral-10: #eaeded;
  --max-neutral-5: #f5f5f5;
  --max-white: #faffff;
  --max-premium-110: #108f92;
  --max-premium-100: #17a398;
  --max-premium-90: #57bdb8;
  --max-premium-10: #b8e4e7;
  --max-premium-5: #d8f1f7;
  --max-vertical3-110: #c62350;
  --max-vertical3-100: #e63462;
  --max-vertical3-90: #ee6e90;
  --max-vertical3-10: #fbc5d4;
  --max-vertical3-5: #ffe2eb;
  --max-vertical4-110: #0b5d62;
  --max-vertical4-100: #0c6a6f;
  --max-vertical4-90: #51979b;
  --max-vertical4-10: #b8dbdc;
  --max-vertical4-5: #daf2f2;
  --max-authorized-110: #d77900;
  --max-authorized-100: #ff9000;
  --max-authorized-90: #ffb14a;
  --max-authorized-10: #ffe3b8;
  --max-authorized-5: #fff3dd;
  --max-yellow-110: #fff339;
  --max-yellow-100: #fff460;
  --max-yellow-90: #fff788;
  --max-yellow-10: #fffaaa;
  --max-yellow-5: #fffdd4;
  --max-marketing-forest-green: #00312d;
  --max-marketing-bush-green: #3b7918;
  --max-marketing-grass-green: #72bf01;
  --max-marketing-mint: #eafde7;
  --max-marketing-iceberg: #f6f8ee;
  --max-warning-100: #ff9505;
  --max-error-100: #bf0603;
  --max-progress-100: #214193;
  --max-info-100: #0267c1;
  --max-component-border: var(--core-border);
  --max-component-text: var(--core-text);
  --max-item-hover: var(--core-hover);

  --tb-white: #fff;
  --tb-kendo-border-radius-sm: 0.125rem;
  --tb-kendo-border-radius-md: 0.25rem;
  --tb-kendo-color-app-surface: #ffffff;
  --tb-kendo-color-on-app-surface: #323130;
  --tb-kendo-color-subtle: #605e5c;
  --tb-kendo-color-surface: #faf9f8;
  --tb-kendo-color-surface-alt: #ffffff;
  --tb-kendo-color-border: #8a8886;
  --tb-kendo-color-border-alt: #323130;
  --tb-kendo-color-base-subtle: #edebe9;
  --tb-kendo-color-base-subtle-hover: #e1dfdd;
  --tb-kendo-color-base-subtle-active: #d2d0ce;
  --tb-kendo-color-base: #ffffff;
  --tb-kendo-color-base-hover: #f3f2f1;
  --tb-kendo-color-base-active: #edebe9;
  --tb-kendo-color-base-emphasis: #605e5c;
  --tb-kendo-color-base-on-subtle: #323130;
  --tb-kendo-color-on-base: #323130;
  --tb-kendo-color-base-on-surface: #323130;
  --tb-kendo-color-primary-subtle: #deecf9;
  --tb-kendo-color-primary-subtle-hover: #c7e0f4;
  --tb-kendo-color-primary-subtle-active: #abd1ef;
  --tb-kendo-color-primary: var(--max-inserv-100);
  --tb-kendo-color-primary-hover: var(--max-inserv-110);
  --tb-kendo-color-primary-active: var(--max-inserv-120);
  --tb-kendo-color-primary-emphasis: #004578;
  --tb-kendo-color-primary-on-subtle: #001c30;
  --tb-kendo-color-on-primary: #ffffff;
  --tb-kendo-color-primary-on-surface: var(--max-inserv-100);
  --tb-kendo-color-secondary-subtle: #faf9f8;
  --tb-kendo-color-secondary-subtle-hover: #f3f2f1;
  --tb-kendo-color-secondary-subtle-active: #edebe9;
  --tb-kendo-color-secondary: #979593;
  --tb-kendo-color-secondary-hover: #8a8886;
  --tb-kendo-color-secondary-active: #797775;
  --tb-kendo-color-secondary-emphasis: #605e5c;
  --tb-kendo-color-secondary-on-subtle: #323130;
  --tb-kendo-color-on-secondary: #ffffff;
  --tb-kendo-color-secondary-on-surface: #605e5c;
  --tb-kendo-color-tertiary-subtle: #deecf9;
  --tb-kendo-color-tertiary-subtle-hover: #bfdbf3;
  --tb-kendo-color-tertiary-subtle-active: #aacfef;
  --tb-kendo-color-tertiary: #2b88d8;
  --tb-kendo-color-tertiary-hover: #287ec7;
  --tb-kendo-color-tertiary-active: #2473b7;
  --tb-kendo-color-tertiary-emphasis: #2169a6;
  --tb-kendo-color-tertiary-on-subtle: #174974;
  --tb-kendo-color-on-tertiary: #ffffff;
  --tb-kendo-color-tertiary-on-surface: #2b88d8;
  --tb-kendo-color-info-subtle: #cee5ff;
  --tb-kendo-color-info-subtle-hover: #add4ff;
  --tb-kendo-color-info-subtle-active: #8dc3ff;
  --tb-kendo-color-info: var(--max-info-100);
  --tb-kendo-color-info-hover: #096edf;
  --tb-kendo-color-info-active: #085ebf;
  --tb-kendo-color-info-emphasis: #064f9f;
  --tb-kendo-color-info-on-subtle: #032040;
  --tb-kendo-color-on-info: #ffffff;
  --tb-kendo-color-info-on-surface: #096edf;
  --tb-kendo-color-success-subtle: #dff6dd;
  --tb-kendo-color-success-subtle-hover: #c8e8c6;
  --tb-kendo-color-success-subtle-active: #b1dbaf;
  --tb-kendo-color-success: var(--max-finserv-100);
  --tb-kendo-color-success-hover: #3e973e;
  --tb-kendo-color-success-active: #278a27;
  --tb-kendo-color-success-emphasis: #107c10;
  --tb-kendo-color-success-on-subtle: #063206;
  --tb-kendo-color-on-success: #ffffff;
  --tb-kendo-color-success-on-surface: #107c10;
  --tb-kendo-color-warning-subtle: #fff4ce;
  --tb-kendo-color-warning-subtle-hover: #ffedad;
  --tb-kendo-color-warning-subtle-active: #ffe58d;
  --tb-kendo-color-warning: var(--max-warning-100);
  --tb-kendo-color-warning-hover: #f5c000;
  --tb-kendo-color-warning-active: #e0b000;
  --tb-kendo-color-warning-emphasis: #4e4d4b;
  --tb-kendo-color-warning-on-subtle: #323130;
  --tb-kendo-color-on-warning: #323130;
  --tb-kendo-color-warning-on-surface: #7a6000;
  --tb-kendo-color-error-subtle: #fde7e9;
  --tb-kendo-color-error-subtle-hover: #f3d2d4;
  --tb-kendo-color-error-subtle-active: #e9bcbf;
  --tb-kendo-color-error: var(--max-error-100);
  --tb-kendo-color-error-hover: #b85156;
  --tb-kendo-color-error-active: #ae3b41;
  --tb-kendo-color-error-emphasis: #a4262c;
  --tb-kendo-color-error-on-subtle: #420f12;
  --tb-kendo-color-on-error: #ffffff;
  --tb-kendo-color-error-on-surface: #a4262c;
  --tb-kendo-color-light-subtle: #faf9f8;
  --tb-kendo-color-light-subtle-hover: #f3f2f1;
  --tb-kendo-color-light-subtle-active: #edebe9;
  --tb-kendo-color-light: #d2d0ce;
  --tb-kendo-color-light-hover: #c8c6c4;
  --tb-kendo-color-light-active: #a19f9d;
  --tb-kendo-color-light-emphasis: #e1dfdd;
  --tb-kendo-color-light-on-subtle: #323130;
  --tb-kendo-color-on-light: #323130;
  --tb-kendo-color-light-on-surface: #d2d0ce;
  --tb-kendo-color-dark-subtle: #a19f9d;
  --tb-kendo-color-dark-subtle-hover: #979593;
  --tb-kendo-color-dark-subtle-active: #8a8886;
  --tb-kendo-color-dark: #323130;
  --tb-kendo-color-dark-hover: #201f1e;
  --tb-kendo-color-dark-active: #000000;
  --tb-kendo-color-dark-emphasis: #797775;
  --tb-kendo-color-dark-on-subtle: #201f1e;
  --tb-kendo-color-on-dark: #ffffff;
  --tb-kendo-color-dark-on-surface: #323130;
  --tb-kendo-color-inverse-subtle: #a19f9d;
  --tb-kendo-color-inverse-subtle-hover: #979593;
  --tb-kendo-color-inverse-subtle-active: #8a8886;
  --tb-kendo-color-inverse: #323130;
  --tb-kendo-color-inverse-hover: #201f1e;
  --tb-kendo-color-inverse-active: #000000;
  --tb-kendo-color-inverse-emphasis: #797775;
  --tb-kendo-color-inverse-on-subtle: #201f1e;
  --tb-kendo-color-on-inverse: #ffffff;
  --tb-kendo-color-inverse-on-surface: #323130;
  --tb-kendo-color-series-a: #0099bc;
  --tb-kendo-color-series-a-bold: #00738d;
  --tb-kendo-color-series-a-bolder: #004d5e;
  --tb-kendo-color-series-a-subtle: #40b3cd;
  --tb-kendo-color-series-a-subtler: #80ccdd;
  --tb-kendo-color-series-b: #e74856;
  --tb-kendo-color-series-b-bold: #ad3641;
  --tb-kendo-color-series-b-bolder: #74242b;
  --tb-kendo-color-series-b-subtle: #ed7680;
  --tb-kendo-color-series-b-subtler: #f3a3aa;
  --tb-kendo-color-series-c: #ffb900;
  --tb-kendo-color-series-c-bold: #bf8b00;
  --tb-kendo-color-series-c-bolder: #805d00;
  --tb-kendo-color-series-c-subtle: #ffcb40;
  --tb-kendo-color-series-c-subtler: #ffdc80;
  --tb-kendo-color-series-d: #096edf;
  --tb-kendo-color-series-d-bold: #064f9f;
  --tb-kendo-color-series-d-bolder: #042f60;
  --tb-kendo-color-series-d-subtle: #2b8fff;
  --tb-kendo-color-series-d-subtler: #6cb2ff;
  --tb-kendo-color-series-e: #8764b8;
  --tb-kendo-color-series-e-bold: #654b8a;
  --tb-kendo-color-series-e-bolder: #44325c;
  --tb-kendo-color-series-e-subtle: #a58bca;
  --tb-kendo-color-series-e-subtler: #c3b1db;
  --tb-kendo-color-series-f: #00cc6a;
  --tb-kendo-color-series-f-bold: #009950;
  --tb-kendo-color-series-f-bolder: #006635;
  --tb-kendo-color-series-f-subtle: #40d98f;
  --tb-kendo-color-series-f-subtler: #80e5b4;
  --tb-kendo-box-shadow-depth-1: 0px 0.3px 0.9px 0 rgba(0, 0, 0, 0.1),
    0px 1.6px 3.6px 0 rgba(0, 0, 0, 0.13);
  --tb-kendo-box-shadow-depth-2: 0px 0.6px 1.8px 0 rgba(0, 0, 0, 0.1),
    0px 3.2px 7.2px 0 rgba(0, 0, 0, 0.13);
  --tb-kendo-box-shadow-depth-3: 0px 0.9px 2.7px 0 rgba(0, 0, 0, 0.1),
    0px 4.8px 10.8px 0 rgba(0, 0, 0, 0.13);
  --tb-kendo-box-shadow-depth-4: 0px 1.2px 3.6px 0 rgba(0, 0, 0, 0.1),
    0px 6.4px 14.4px 0 rgba(0, 0, 0, 0.13);

  --kendo-border-radius-sm: var(--tb-kendo-border-radius-sm);
  --kendo-border-radius-md: var(--tb-kendo-border-radius-md);
  --kendo-color-app-surface: var(--tb-kendo-color-app-surface);
  --kendo-color-on-app-surface: var(--tb-kendo-color-on-app-surface);
  --kendo-color-subtle: var(--tb-kendo-color-subtle);
  --kendo-color-surface: var(--tb-kendo-color-surface);
  --kendo-color-surface-alt: var(--tb-kendo-color-surface-alt);
  --kendo-color-border: var(--tb-kendo-color-border);
  --kendo-color-border-alt: var(--tb-kendo-color-border-alt);
  --kendo-color-base-subtle: var(--tb-kendo-color-base-subtle);
  --kendo-color-base-subtle-hover: var(--tb-kendo-color-base-subtle-hover);
  --kendo-color-base-subtle-active: var(--tb-kendo-color-base-subtle-active);
  --kendo-color-base: var(--tb-kendo-color-base);
  --kendo-color-base-hover: var(--tb-kendo-color-base-hover);
  --kendo-color-base-active: var(--tb-kendo-color-base-active);
  --kendo-color-base-emphasis: var(--tb-kendo-color-base-emphasis);
  --kendo-color-base-on-subtle: var(--tb-kendo-color-base-on-subtle);
  --kendo-color-on-base: var(--tb-kendo-color-on-base);
  --kendo-color-base-on-surface: var(--tb-kendo-color-base-on-surface);
  --kendo-color-primary-subtle: var(--tb-kendo-color-primary-subtle);
  --kendo-color-primary-subtle-hover: var(
    --tb-kendo-color-primary-subtle-hover
  );
  --kendo-color-primary-subtle-active: var(
    --tb-kendo-color-primary-subtle-active
  );
  --kendo-color-primary: var(--tb-kendo-color-primary);
  --kendo-color-primary-hover: var(--tb-kendo-color-primary-hover);
  --kendo-color-primary-active: var(--tb-kendo-color-primary-active);
  --kendo-color-primary-emphasis: var(--tb-kendo-color-primary-emphasis);
  --kendo-color-primary-on-subtle: var(--tb-kendo-color-primary-on-subtle);
  --kendo-color-on-primary: var(--tb-kendo-color-on-primary);
  --kendo-color-primary-on-surface: var(--tb-kendo-color-primary-on-surface);
  --kendo-color-secondary-subtle: var(--tb-kendo-color-secondary-subtle);
  --kendo-color-secondary-subtle-hover: var(
    --tb-kendo-color-secondary-subtle-hover
  );
  --kendo-color-secondary-subtle-active: var(
    --tb-kendo-color-secondary-subtle-active
  );
  --kendo-color-secondary: var(--tb-kendo-color-secondary);
  --kendo-color-secondary-hover: var(--tb-kendo-color-secondary-hover);
  --kendo-color-secondary-active: var(--tb-kendo-color-secondary-active);
  --kendo-color-secondary-emphasis: var(--tb-kendo-color-secondary-emphasis);
  --kendo-color-secondary-on-subtle: var(--tb-kendo-color-secondary-on-subtle);
  --kendo-color-on-secondary: var(--tb-kendo-color-on-secondary);
  --kendo-color-secondary-on-surface: var(
    --tb-kendo-color-secondary-on-surface
  );
  --kendo-color-tertiary-subtle: var(--tb-kendo-color-tertiary-subtle);
  --kendo-color-tertiary-subtle-hover: var(
    --tb-kendo-color-tertiary-subtle-hover
  );
  --kendo-color-tertiary-subtle-active: var(
    --tb-kendo-color-tertiary-subtle-active
  );
  --kendo-color-tertiary: var(--tb-kendo-color-tertiary);
  --kendo-color-tertiary-hover: var(--tb-kendo-color-tertiary-hover);
  --kendo-color-tertiary-active: var(--tb-kendo-color-tertiary-active);
  --kendo-color-tertiary-emphasis: var(--tb-kendo-color-tertiary-emphasis);
  --kendo-color-tertiary-on-subtle: var(--tb-kendo-color-tertiary-on-subtle);
  --kendo-color-on-tertiary: var(--tb-kendo-color-on-tertiary);
  --kendo-color-tertiary-on-surface: var(--tb-kendo-color-tertiary-on-surface);
  --kendo-color-info-subtle: var(--tb-kendo-color-info-subtle);
  --kendo-color-info-subtle-hover: var(--tb-kendo-color-info-subtle-hover);
  --kendo-color-info-subtle-active: var(--tb-kendo-color-info-subtle-active);
  --kendo-color-info: var(--tb-kendo-color-info);
  --kendo-color-info-hover: var(--tb-kendo-color-info-hover);
  --kendo-color-info-active: var(--tb-kendo-color-info-active);
  --kendo-color-info-emphasis: var(--tb-kendo-color-info-emphasis);
  --kendo-color-info-on-subtle: var(--tb-kendo-color-info-on-subtle);
  --kendo-color-on-info: var(--tb-kendo-color-on-info);
  --kendo-color-info-on-surface: var(--tb-kendo-color-info-on-surface);
  --kendo-color-success-subtle: var(--tb-kendo-color-success-subtle);
  --kendo-color-success-subtle-hover: var(
    --tb-kendo-color-success-subtle-hover
  );
  --kendo-color-success-subtle-active: var(
    --tb-kendo-color-success-subtle-active
  );
  --kendo-color-success: var(--tb-kendo-color-success);
  --kendo-color-success-hover: var(--tb-kendo-color-success-hover);
  --kendo-color-success-active: var(--tb-kendo-color-success-active);
  --kendo-color-success-emphasis: var(--tb-kendo-color-success-emphasis);
  --kendo-color-success-on-subtle: var(--tb-kendo-color-success-on-subtle);
  --kendo-color-on-success: var(--tb-kendo-color-on-success);
  --kendo-color-success-on-surface: var(--tb-kendo-color-success-on-surface);
  --kendo-color-warning-subtle: var(--tb-kendo-color-warning-subtle);
  --kendo-color-warning-subtle-hover: var(
    --tb-kendo-color-warning-subtle-hover
  );
  --kendo-color-warning-subtle-active: var(
    --tb-kendo-color-warning-subtle-active
  );
  --kendo-color-warning: var(--tb-kendo-color-warning);
  --kendo-color-warning-hover: var(--tb-kendo-color-warning-hover);
  --kendo-color-warning-active: var(--tb-kendo-color-warning-active);
  --kendo-color-warning-emphasis: var(--tb-kendo-color-warning-emphasis);
  --kendo-color-warning-on-subtle: var(--tb-kendo-color-warning-on-subtle);
  --kendo-color-on-warning: var(--tb-kendo-color-on-warning);
  --kendo-color-warning-on-surface: var(--tb-kendo-color-warning-on-surface);
  --kendo-color-error-subtle: var(--tb-kendo-color-error-subtle);
  --kendo-color-error-subtle-hover: var(--tb-kendo-color-error-subtle-hover);
  --kendo-color-error-subtle-active: var(--tb-kendo-color-error-subtle-active);
  --kendo-color-error: var(--tb-kendo-color-error);
  --kendo-color-error-hover: var(--tb-kendo-color-error-hover);
  --kendo-color-error-active: var(--tb-kendo-color-error-active);
  --kendo-color-error-emphasis: var(--tb-kendo-color-error-emphasis);
  --kendo-color-error-on-subtle: var(--tb-kendo-color-error-on-subtle);
  --kendo-color-on-error: var(--tb-kendo-color-on-error);
  --kendo-color-error-on-surface: var(--tb-kendo-color-error-on-surface);
  --kendo-color-light-subtle: var(--tb-kendo-color-light-subtle);
  --kendo-color-light-subtle-hover: var(--tb-kendo-color-light-subtle-hover);
  --kendo-color-light-subtle-active: var(--tb-kendo-color-light-subtle-active);
  --kendo-color-light: var(--tb-kendo-color-light);
  --kendo-color-light-hover: var(--tb-kendo-color-light-hover);
  --kendo-color-light-active: var(--tb-kendo-color-light-active);
  --kendo-color-light-emphasis: var(--tb-kendo-color-light-emphasis);
  --kendo-color-light-on-subtle: var(--tb-kendo-color-light-on-subtle);
  --kendo-color-on-light: var(--tb-kendo-color-on-light);
  --kendo-color-light-on-surface: var(--tb-kendo-color-light-on-surface);
  --kendo-color-dark-subtle: var(--tb-kendo-color-dark-subtle);
  --kendo-color-dark-subtle-hover: var(--tb-kendo-color-dark-subtle-hover);
  --kendo-color-dark-subtle-active: var(--tb-kendo-color-dark-subtle-active);
  --kendo-color-dark: var(--tb-kendo-color-dark);
  --kendo-color-dark-hover: var(--tb-kendo-color-dark-hover);
  --kendo-color-dark-active: var(--tb-kendo-color-dark-active);
  --kendo-color-dark-emphasis: var(--tb-kendo-color-dark-emphasis);
  --kendo-color-dark-on-subtle: var(--tb-kendo-color-dark-on-subtle);
  --kendo-color-on-dark: var(--tb-kendo-color-on-dark);
  --kendo-color-dark-on-surface: var(--tb-kendo-color-dark-on-surface);
  --kendo-color-inverse-subtle: var(--tb-kendo-color-inverse-subtle);
  --kendo-color-inverse-subtle-hover: var(
    --tb-kendo-color-inverse-subtle-hover
  );
  --kendo-color-inverse-subtle-active: var(
    --tb-kendo-color-inverse-subtle-active
  );
  --kendo-color-inverse: var(--tb-kendo-color-inverse);
  --kendo-color-inverse-hover: var(--tb-kendo-color-inverse-hover);
  --kendo-color-inverse-active: var(--tb-kendo-color-inverse-active);
  --kendo-color-inverse-emphasis: var(--tb-kendo-color-inverse-emphasis);
  --kendo-color-inverse-on-subtle: var(--tb-kendo-color-inverse-on-subtle);
  --kendo-color-on-inverse: var(--tb-kendo-color-on-inverse);
  --kendo-color-inverse-on-surface: var(--tb-kendo-color-inverse-on-surface);
  --kendo-color-series-a: var(--tb-kendo-color-series-a);
  --kendo-color-series-a-bold: var(--tb-kendo-color-series-a-bold);
  --kendo-color-series-a-bolder: var(--tb-kendo-color-series-a-bolder);
  --kendo-color-series-a-subtle: var(--tb-kendo-color-series-a-subtle);
  --kendo-color-series-a-subtler: var(--tb-kendo-color-series-a-subtler);
  --kendo-color-series-b: var(--tb-kendo-color-series-b);
  --kendo-color-series-b-bold: var(--tb-kendo-color-series-b-bold);
  --kendo-color-series-b-bolder: var(--tb-kendo-color-series-b-bolder);
  --kendo-color-series-b-subtle: var(--tb-kendo-color-series-b-subtle);
  --kendo-color-series-b-subtler: var(--tb-kendo-color-series-b-subtler);
  --kendo-color-series-c: var(--tb-kendo-color-series-c);
  --kendo-color-series-c-bold: var(--tb-kendo-color-series-c-bold);
  --kendo-color-series-c-bolder: var(--tb-kendo-color-series-c-bolder);
  --kendo-color-series-c-subtle: var(--tb-kendo-color-series-c-subtle);
  --kendo-color-series-c-subtler: var(--tb-kendo-color-series-c-subtler);
  --kendo-color-series-d: var(--tb-kendo-color-series-d);
  --kendo-color-series-d-bold: var(--tb-kendo-color-series-d-bold);
  --kendo-color-series-d-bolder: var(--tb-kendo-color-series-d-bolder);
  --kendo-color-series-d-subtle: var(--tb-kendo-color-series-d-subtle);
  --kendo-color-series-d-subtler: var(--tb-kendo-color-series-d-subtler);
  --kendo-color-series-e: var(--tb-kendo-color-series-e);
  --kendo-color-series-e-bold: var(--tb-kendo-color-series-e-bold);
  --kendo-color-series-e-bolder: var(--tb-kendo-color-series-e-bolder);
  --kendo-color-series-e-subtle: var(--tb-kendo-color-series-e-subtle);
  --kendo-color-series-e-subtler: var(--tb-kendo-color-series-e-subtler);
  --kendo-color-series-f: var(--tb-kendo-color-series-f);
  --kendo-color-series-f-bold: var(--tb-kendo-color-series-f-bold);
  --kendo-color-series-f-bolder: var(--tb-kendo-color-series-f-bolder);
  --kendo-color-series-f-subtle: var(--tb-kendo-color-series-f-subtle);
  --kendo-color-series-f-subtler: var(--tb-kendo-color-series-f-subtler);
  --kendo-box-shadow-depth-1: var(--tb-kendo-box-shadow-depth-1);
  --kendo-box-shadow-depth-2: var(--tb-kendo-box-shadow-depth-2);
  --kendo-box-shadow-depth-3: var(--tb-kendo-box-shadow-depth-3);
  --kendo-box-shadow-depth-4: var(--tb-kendo-box-shadow-depth-4);

  --kendo-font-family: Roboto, sans-serif;
  --kendo-font-size: 14px;
  --kendo-font-size-xs: 10px;
  --kendo-font-size-sm: 12px;
  --kendo-font-size-md: 14px;
  --kendo-font-size-lg: 16px;
  --kendo-font-size-xl: 20px;
  --kendo-font-weight: 400;
  --kendo-line-height: 1.4285714286;
  --kendo-line-height-xs: 1;
  --kendo-line-height-sm: 1.25;
  --kendo-line-height-md: 1.4285714286;
  --kendo-line-height-lg: 1.5;
  --kendo-font-weight: 400;
  --kendo-font-weight-light: 300;
  --kendo-font-weight-bold: 600;
  --kendo-letter-spacing: normal;
  --kendo-disabled-text: var(--max-neutral-60);
  --kendo-invalid-text: var(--max-error-100);
  --kendo-invalid-border: var(--max-error-100);
  --kendo-component-bg: var(--tb-kendo-color-app-surface);

  --kendo-link-text: var(--kendo-primary-100, inherit);
  --kendo-link-hover-text: var(--kendo-primary-120, inherit);

  --kendo-checkbox-indeterminate-text: var(--max-inserv-100);
  --kendo-chip-solid-base-bg: var(--max-inserv-100);
  --kendo-chip-solid-base-text: var(--tb-white);
  --kendo-chip-solid-base-hover-bg: var(--max-inserv-100);
  --kendo-chip-solid-base-hover-text: var(--tb-white);

  --kendo-tooltip-padding-x: 1rem;
  --kendo-tooltip-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1),
    0px 6.4px 14.4px rgba(0, 0, 0, 0.13);

  --kendo-input-focus-border-width: 0;
  --kendo-input-focus-shadow: 0 0 7px 0 var(--max-inserv-90);
  --kendo-input-hover-shadow: 0 3px 6px rgb(0 0 0 / 20%),
    0 3px 6px rgb(0 0 0 / 26%);
  --kendo-input-solid-border: var(--max-component-border);
  --kendo-input-solid-disabled-bg: var(--kendo-neutral-30);

  --kendo-form-label-margin-bottom: 2px;

  --kendo-list-item-selected-bg: var(--max-inserv-5);
  --kendo-list-item-selected-text: var(--max-component-text);
  --kendo-list-item-selected-hover-bg: var(--max-inserv-10);
  --kendo-list-item-focus-shadow: none;
  --kendo-list-item-hover-bg: var(--max-inserv-10);
  --kendo-list-item-hover-text: var(--max-component-text);

  --kendo-menu-popup-border-width: 1px;
  --kendo-menu-popup-border: var(--max-component-border);
  --kendo-menu-popup-item-focus-outline: var(--max-inserv-90);
  --kendo-menu-popup-item-hover-bg: var(--max-item-hover);
  --kendo-menu-popup-item-expanded-bg: var(--max-item-hover);
  --kendo-menu-popup-item-padding-y-sm: 0;
  --kendo-menu-separator-margin: 0;
  --kendo-popup-border: var(--max-component-border);
  --kendo-popup-border-width: 1px;
  --kendo-popup-text: var(--max-component-text);

  --kendo-listview-border: var(--max-component-border);
  --kendo-listview-header-border: var(--max-component-border);
  --kendo-listview-header-bg: var(--max-neutral-5);
  --kendo-listview-header-padding-y: 0.375rem;
  --kendo-listview-header-padding-x: 0.75rem;

  --kendo-button-font-weight: 500;
  --kendo-button-sm-font-size: 0.75rem;
  --kendo-button-sm-line-height: 0.75rem;
  --kendo-button-sm-padding-x: 0.5rem;
  --kendo-button-md-font-size: 0.875rem;
  --kendo-button-md-line-height: 1.025rem;
  --kendo-button-md-padding-x: 0.75rem;
  --kendo-button-disabled-bg: var(--max-neutral-30);
  --kendo-button-disabled-text: var(--tb-white);
  --kendo-button-solid-primary-focus-bg: var(--max-inserv-120);
  --kendo-button-solid-primary-focus-border: var(--max-inserv-120);
  --kendo-button-solid-primary-active-bg: var(--max-inserv-110);
  --kendo-button-solid-primary-active-border: var(--max-inserv-110);
  --kendo-button-solid-primary-disabled-text: var(--tb-white);
  --kendo-button-solid-primary-disabled-bg: var(--max-neutral-30);
  --kendo-button-solid-secondary-text: var(--max-neutral-90);
  --kendo-button-solid-secondary-bg: var(--tb-white);
  --kendo-button-solid-secondary-border: var(--max-neutral-70);
  --kendo-button-solid-secondary-hover-text: var(--max-neutral-90);
  --kendo-button-solid-secondary-hover-bg: var(--max-neutral-5);
  --kendo-button-solid-secondary-hover-border: var(--max-neutral-90);
  --kendo-button-solid-secondary-focus-text: var(--max-neutral-90);
  --kendo-button-solid-secondary-focus-bg: var(--max-neutral-5);
  --kendo-button-solid-secondary-focus-border: var(--max-neutral-70);
  --kendo-button-solid-secondary-focus-outline: var(--max-neutral-70);
  --kendo-button-solid-secondary-focus-hover-text: var(--max-neutral-90);
  --kendo-button-solid-secondary-focus-hover-bg: var(--max-neutral-5);
  --kendo-button-solid-secondary-focus-hover-border: var(--max-neutral-90);
  --kendo-button-solid-secondary-active-text: var(--max-neutral-90);
  --kendo-button-solid-secondary-active-bg: var(--max-neutral-20);
  --kendo-button-solid-secondary-active-border: var(--max-neutral-70);
  --kendo-button-solid-secondary-active-hover-text: var(--max-neutral-90);
  --kendo-button-solid-secondary-active-hover-bg: var(--max-neutral-5);
  --kendo-button-solid-secondary-active-hover-border: var(--max-neutral-90);
  --kendo-button-solid-secondary-selected-text: var(--max-neutral-90);
  --kendo-button-solid-secondary-selected-bg: var(--max-neutral-20);
  --kendo-button-solid-secondary-selected-border: var(--max-neutral-70);
  --kendo-button-solid-secondary-selected-hover-text: var(--max-neutral-90);
  --kendo-button-solid-secondary-selected-hover-bg: var(--max-neutral-5);
  --kendo-button-solid-secondary-selected-hover-border: var(--max-neutral-90);
  --kendo-button-solid-secondary-disabled-text: var(--max-neutral-40);
  --kendo-button-solid-secondary-disabled-bg: var(--tb-white);
  --kendo-button-solid-secondary-disabled-border: var(--max-neutral-40);
  --kendo-button-flat-primary-text: var(--max-inserv-100);
  --kendo-button-flat-primary-bg: transparent;
  --kendo-button-flat-primary-border: transparent;
  --kendo-button-flat-primary-hover-text: var(--max-inserv-100);
  --kendo-button-flat-primary-hover-bg: var(--max-inserv-5);
  --kendo-button-flat-primary-hover-border: transparent;
  --kendo-button-flat-primary-focus-text: var(--max-inserv-100);
  --kendo-button-flat-primary-focus-bg: var(--max-inserv-5);
  --kendo-button-flat-primary-focus-border: var(--max-inserv-10);
  --kendo-button-flat-primary-focus-outline: var(--max-inserv-10);
  --kendo-button-flat-primary-focus-hover-text: var(--max-inserv-100);
  --kendo-button-flat-primary-focus-hover-bg: var(--max-inserv-5);
  --kendo-button-flat-primary-focus-hover-border: transparent;
  --kendo-button-flat-primary-active-text: var(--max-inserv-100);
  --kendo-button-flat-primary-active-bg: var(--max-inserv-10);
  --kendo-button-flat-primary-active-border: transparent;
  --kendo-button-flat-primary-active-hover-text: var(--max-inserv-100);
  --kendo-button-flat-primary-active-hover-bg: var(--max-inserv-5);
  --kendo-button-flat-primary-active-hover-border: transparent;
  --kendo-button-flat-primary-selected-text: var(--max-inserv-100);
  --kendo-button-flat-primary-selected-bg: var(--max-inserv-10);
  --kendo-button-flat-primary-selected-border: transparent;
  --kendo-button-flat-primary-selected-hover-text: var(--max-inserv-100);
  --kendo-button-flat-primary-selected-hover-bg: var(--max-inserv-5);
  --kendo-button-flat-primary-selected-hover-border: transparent;
  --kendo-button-flat-primary-disabled-text: var(--max-neutral-40);
  --kendo-button-flat-primary-disabled-bg: transparent;
  --kendo-button-flat-primary-disabled-border: transparent;

  --kendo-dialog-titlebar-bg: var(--max-neutral-5);
  --kendo-dialog-titlebar-border: var(--max-component-border);
  --kendo-dialog-titlebar-border-width: 0 0 1px;
  --kendo-dialog-titlebar-padding-x: 0.75rem;
  --kendo-dialog-titlebar-padding-y: 0.375rem;
  --kendo-dialog-titlebar-text: var(--max-component-text);
  --kendo-window-title-font-size: 1rem;
  --kendo-window-title-font-weight: normal;
  --kendo-overlay-dark: var(--max-neutral-40);
  --kendo-overlay-opacity: 0.3;
  --kendo-dialog-light-bg: var(--tb-white);
  --kendo-dialog-light-border: var(--tb-white);

  --kendo-picker-solid-border: var(--max-component-border);

  --kendo-grid-border: var(--max-component-border);
  --kendo-grid-header-border: var(--max-component-border);
  --kendo-grid-header-bg: var(--max-neutral-5);
  --kendo-grid-header-font-weight: 500;
  --kendo-grid-header-padding-y-md: 0.375rem;
  --kendo-grid-hover-bg: var(--max-inserv-10);
  --kendo-table-header-bg: var(--max-neutral-5);
  --kendo-toolbar-border: var(--max-component-border);
  --kendo-grid-sticky-cell-border-width: 0;
  --kendo-grid-sticky-header-border: var(--max-component-border);
  --kendo-grid-grouping-row-border: var(--max-component-border);
  --kendo-grid-grouping-row-bg: var(--max-neutral-10);
  --kendo-grid-sticky-header-bg: var(--max-neutral-5);
  --kendo-grid-sticky-alt-bg: var(--max-neutral-5);
  --kendo-grid-grouping-header-border: var(--max-component-border);
  --kendo-grid-selected-bg: var(--max-inserv-5);
  --kendo-grid-selected-border: var(--max-component-border);
  --kendo-table-selected-bg: var(--max-inserv-5);
  --kendo-table-selected-border: var(--max-component-border);

  --kendo-tabstrip-content-padding-x: 0px;
  --kendo-tabstrip-content-padding-y: 0px;
  --kendo-tabstrip-indicator-color: var(--max-inserv-100);
  --kendo-toolbar-bg: var(--max-white);
  --kendo-toolbar-md-padding-x: 0.375rem;
  --kendo-toolbar-md-padding-y: 0.3rem;

  --kendo-panelbar-border: var(--max-component-border);
  --kendo-panelbar-item-border-width: 1px;
  --kendo-panelbar-header-focus-shadow: 0px;
  --kendo-panelbar-selected-marker: transparent;
  --kendo-panelbar-header-bg: transparent;
  --kendo-panelbar-header-selected-hover-text: var(--max-black);
  --kendo-panelbar-header-selected-text: var(--max-black);
  --kendo-panelbar-header-hover-text: var(--max-black);
  --kendo-panelbar-header-hover-bg: var(--max-inserv-10);
  --kendo-panelbar-header-selected-hover-focus-text: var(--max-black);
  --kendo-panelbar-header-padding-x-start: 1rem;
  --kendo-panelbar-header-padding-x-end: 1rem;
  --kendo-panelbar-header-padding-y: 0.5rem;

  --kendo-breadcrumb-root-link-focus-shadow: none;
  --kendo-breadcrumb-link-focus-shadow: none;
  --kendo-breadcrumb-text: var(--max-component-text);
  --kendo-breadcrumb-root-link-text: var(--max-component-text);
  --kendo-breadcrumb-link-text: var(--max-component-text);
  --kendo-breadcrumb-link-disabled-text: var(--max-component-text);
  --kendo-breadcrumb-font-size: 1rem;
  --kendo-breadcrumb-delimiter-icon-font-size: 1.375rem;
  --kendo-breadcrumb-link-padding-y: 0;
  --kendo-breadcrumb-link-padding-x: 0;

  --kendo-expander-header-text: var(--max-black);
  --kendo-expander-bg: transparent;
  --kendo-expander-header-hover-bg: transparent;
  --kendo-expander-focus-offset: 0;
  --kendo-expander-content-padding-y: 0;

  --kendo-neutral-20: var(--max-neutral-20);
  --kendo-neutral-30: var(--max-neutral-30);
  --kendo-neutral-90: var(--max-neutral-90);
  --kendo-notification-success-text: var(--tb-white);
  --kendo-notification-error-text: var(--tb-white);
  --kendo-notification-info-text: var(--tb-white);
  --kendo-notification-warning-text: var(--tb-white);
  --kendo-notification-success-bg: var(--max-finserv-100);
  --kendo-notification-success-border: var(--max-finserv-100);
  --kendo-notification-error-bg: var(--max-error-100);
  --kendo-notification-error-border: var(--max-error-100);
  --kendo-notification-info-bg: var(--max-info-100);
  --kendo-notification-info-border: var(--max-info-100);
  --kendo-notification-warning-bg: var(--max-warning-100);
  --kendo-notification-warning-border: var(--max-warning-100);
  --kendo-notification-bg: var(--max-yellow-10);
  --kendo-notification-border: var(--max-yellow-10);
  --kendo-notification-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  --kendo-checkbox-focus-border: var(--max-neutral-70);
  --kendo-checkbox-hover-border: var(--max-neutral-70);
  --kendo-checkbox-checked-border: var(--max-inserv-100);
  --kendo-checkbox-checked-bg: var(--max-inserv-100);
  --kendo-checkbox-border-width: 1px;
  --kendo-checkbox-border: var(--core-border);

  --kendo-tabstrip-item-hover-bg: var(--max-inserv-5);
  --kendo-tabstrip-item-disabled-bg: transparent;
  --kendo-tabstrip-item-disabled-border: transparent;
  --kendo-tabstrip-item-disabled-text: var(--max-neutral-40);
  --kendo-tabstrip-item-selected-font-weight: 500;
  --kendo-tabstrip-item-selected-bg: transparent;
  --kendo-tabstrip-item-selected-border: transparent;
  --kendo-tabstrip-content-focus-border: transparent;

  --kendo-radio-border: var(--max-neutral-90);
  --kendo-radio-hover-border: var(--max-black);
  --kendo-radio-hover-text: var(--max-black);

  --kendo-treeview-sm-item-padding-x: 0.25rem;
  --kendo-treeview-sm-item-padding-y: 0.125rem;
  --kendo-selected-text: var(--max-white);
  --kendo-treeview-item-selected-bg: var(--max-inserv-100);

  --kendo-editor-border: var(--max-component-border);

  --kendo-calendar-range-bg: var(--max-inserv-10);
  --kendo-calendar-range-border: var(--max-inserv-100);
  --kendo-calendar-range-hover-border: var(--max-inserv-100);
  --kendo-calendar-cell-selected-text: var(--tb-white);
  --kendo-calendar-cell-selected-hover-text: var(--tb-white);
  --kendo-calendar-cell-selected-hover-bg: var(--max-inserv-110);
  --kendo-calendar-cell-selected-bg: var(--max-inserv-100);
  --kendo-calendar-today-text: var(--max-inserv-110);
  --kendo-calendar-today-bg: var(--max-inserv-10);
  --kendo-calendar-cell-hover-text: var(--max-inserv-100);
  --kendo-calendar-cell-hover-bg: var(--max-inserv-10);
}
