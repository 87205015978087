.max-ng {
  font-family: Roboto, sans-serif;

  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  *:not([class*='k-']),
  *:not([class*='k-'])::before,
  *:not([class*='k-'])::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
  }
}

@tailwind components;
@tailwind utilities;
